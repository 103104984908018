/* eslint-disable prefer-template */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable react/self-closing-comp */
/* eslint-disable arrow-body-style */

import { useEffect, useState } from 'react';
import './transaction.css';
import * as React from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import moment from 'moment-timezone';
import Skeleton from '@mui/material/Skeleton';
import DatePicker from 'react-datepicker';
import { Form, FormControl, Modal } from 'react-bootstrap';
import { format, startOfMonth, subMonths } from 'date-fns';
import { formatSecondsToMinutesAndSeconds } from '../../utils/formatDuration';
import Subcription from '../Subcription/Subcription';
import { Instance } from '../../config/Http';
import TgSubcription from '../TgSubcription/TgSubcription';

const TgTransaction = () => {
  const [data, setData] = useState([]);
  const [posts, SetPosts] = useState(data);
  const [postPerPage, SetPostPerPage] = useState(10);
  const [currentPage, SetCurrentPage] = useState(1);
  const [lastDocId, setLastDocId] = useState('');
  const [totalLogs, setTotalLogs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({});
  const [timeZone, setTimeZone] = useState('');
  const [numOfPages, setNumberOfPages] = useState(Math.ceil(totalLogs / postPerPage));
  const [numOfButtons, setNumOfButtons] = useState(Array.from({ length: numOfPages }, (_, i) => i + 1));
  const [dateRange, setDateRange] = useState([startOfMonth(new Date()), new Date()]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showExportModal, setShowExportModal] = useState(false);
  const [pageIndex, setPageIndex] = useState([{ start: 0, end: 10 }]);

  useEffect(() => {
    if (dateRange[0]) {
      setStartDate(dateRange[0]);
    } else {
      setStartDate(undefined);
    }

    if (dateRange[1]) {
      setEndDate(dateRange[1]);
    } else {
      setEndDate(undefined);
    }
  }, [dateRange]);

  const prevPageClick = () => {
    if (currentPage > 0) {
      const newCurrentPage = currentPage - 1;
      SetCurrentPage(newCurrentPage);
      const newStart = pageIndex[newCurrentPage].start;
      const newEnd = pageIndex[newCurrentPage].end;
      SetPosts(data.slice(newStart, newEnd));
    }
  };

  const nextPageClick = () => {
    const nextPageIndex = currentPage + 1;
    const pageIndexExist = pageIndex[nextPageIndex];
    if (pageIndexExist) {
      SetCurrentPage(nextPageIndex);
      const newStart = pageIndex[nextPageIndex].start;
      const newEnd = pageIndex[nextPageIndex].end;
      SetPosts(data.slice(newStart, newEnd));
    } else {
      fetchFilteredData();
    }
  };

  const OnPerPostChangeEvent = (e) => {
    const pageSize = e.target.value;
    if (data.length >= pageSize) {
      SetPosts(data.slice(0, pageSize));
    } else if (data.length < pageSize) {
      // find the difference between the new page size and the current data length
      const difference = pageSize - data.length;
      fetchAdditionalData(difference);
    } else {
      fetchData(pageSize);
    }

    // create the page index array based on the new page size
    const newPageIndex = Array.from({ length: Math.ceil(data.length / pageSize) }, (_, i) => {
      return { start: i * pageSize, end: (i + 1) * pageSize };
    });
    setPageIndex(newPageIndex);
    SetPostPerPage(pageSize);
    SetCurrentPage(0);
  };

  useEffect(() => {
    const getTimeZone = () => {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      setTimeZone(timeZone);
      fetchSummary();
      fetchData();
    };

    getTimeZone();
  }, []);

  const fetchSummary = async () => {
    try {
      const summaryResponse = await Instance.get(`/billing/logs-summary`);
      setSummary(summaryResponse.data.summary);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchData = async (perPageLimit) => {
    setLoading(true);

    const limit = perPageLimit || postPerPage;

    try {
      const response = await Instance.get(`/billing/logs?timezone=${timeZone}&limit=${limit}`);
      setLastDocId(response.data.lastDocId);
      setTotalLogs(response.data.totalLogs);
      setData(response.data.logs);
      SetPosts(response.data.logs);
      setPageIndex([
        {
          start: 0,
          end: postPerPage,
        },
      ]);
      SetCurrentPage(0);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const refreshData = async () => {
    setLoading(true);
    try {
      const summaryResponse = await Instance.get(`/billing/logs-summary`);
      setSummary(summaryResponse.data.summary);

      const response = await Instance.get(`/billing/logs?timezone=${timeZone}&limit=${postPerPage}`);
      setLastDocId(response.data.lastDocId);
      setTotalLogs(response.data.totalLogs);
      setData(response.data.logs);
      SetPosts(response.data.logs);
      setPageIndex([
        {
          start: 0,
          end: postPerPage,
        },
      ]);
      SetCurrentPage(0);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const fetchFilteredData = async () => {
    setLoading(true);
    try {
      const response = await Instance.get(
        `/billing/logs?timezone=${timeZone}&lastDocId=${lastDocId}&limit=${postPerPage}`
      );
      setLastDocId(response.data.lastDocId);
      setTotalLogs(response.data.totalLogs);
      setData([...data, ...response.data.logs]);
      SetPosts(response.data.logs);
      setLoading(false);
      setPageIndex([
        ...pageIndex,
        { start: pageIndex[currentPage].end, end: pageIndex[currentPage].end + postPerPage },
      ]);
      SetCurrentPage(currentPage + 1);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const fetchAdditionalData = async (limit) => {
    setLoading(true);
    try {
      const response = await Instance.get(`/billing/logs?timezone=${timeZone}&lastDocId=${lastDocId}&limit=${limit}`);
      setLastDocId(response.data.lastDocId);
      setTotalLogs(response.data.totalLogs);
      setData([...data, ...response.data.logs]);
      SetPosts([...posts, ...response.data.logs]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  // const exportToExcel = () => {
  //   if (data.length === 0) return;

  //   const filteredData = data.map(
  //     ({
  //       id,
  //       updatedAt,
  //       walletBalance,
  //       price,
  //       to,
  //       from,
  //       messageCarrierFees,
  //       description,
  //       messageSegments,
  //       callDuration,
  //       callStatus,
  //       smsStatus,
  //     }) => ({
  //       id,
  //       updatedAt,
  //       to,
  //       from,
  //       price,
  //       walletBalance,
  //       callStatus,
  //       callDuration,
  //       smsStatus,
  //       messageSegments,
  //       messageCarrierFees,
  //       description,
  //     })
  //   ); // Example: Exporting only 'id' and 'name'

  //   const csvContent =
  //     'data:text/csv;charset=utf-8,' +
  //     'ID,Timestamp,To,From,Description,price,SMS Status,SMS Segments,Carrier Fees,Call Status,Call Duration,Wallet Balance\n' + // Column names
  //     filteredData
  //       .map((row) => [
  //         row.id,
  //         moment.tz(row.updatedAt.toDate(), timeZone).format('MMMM Do YYYY, h:mm:ss a'),
  //         row.to,
  //         row.from,
  //         row.description,
  //         row.price,
  //         row.smsStatus,
  //         row.messageSegments,
  //         row.messageCarrierFees,
  //         row.callStatus,
  //         row.callDuration,
  //         row.walletBalance,
  //       ])
  //       .join('\n');
  //   const encodedUri = encodeURI(csvContent);
  //   const link = document.createElement('a');
  //   link.setAttribute('href', encodedUri);
  //   link.setAttribute('download', 'data.csv');
  //   document.body.appendChild(link);
  //   link.click();
  // };
  const handleLogExport = async (e) => {
    e.preventDefault();
    if (dateRange[0] && dateRange[1]) {
      const response = await Instance.get(
        `/billing/export-logs?from=${format(dateRange[0], 'yyyy-MM-dd')}&to=${format(
          dateRange[1],
          'yyyy-MM-dd'
        )}&timezone=${timeZone}`,
        {
          startDate: dateRange[0],
          endDate: dateRange[1],
        }
      );
      alert(`Your request has been submitted. You will receive an email shortly with the transaction logs.`);
      setShowExportModal(false);
    } else {
      alert('Please select a date range');
    }
  };
  const loader = [1, 2, 3, 4, 5];

  return (
    <>
      <div className="transaction-card">
        {summary && <TgSubcription summary={summary} setTimeZone={setTimeZone} />}
        <div className="">
          <>
            <div className="container-fluid mt-5 mb-5">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="d-flex justify-content-between py-3">
                        <div>
                          <select
                            className="form-select ms-5"
                            aria-label="Default select example"
                            onChange={OnPerPostChangeEvent}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <div className="button-export me-5">
                          <Stack direction="row" spacing={2}>
                            <Button variant="outlined" startIcon={<RefreshIcon />} onClick={refreshData}>
                              Refresh
                            </Button>
                            <Button
                              variant="outlined"
                              startIcon={<IosShareRoundedIcon />}
                              onClick={() => {
                                setShowExportModal(!showExportModal);
                              }}
                            >
                              Export
                            </Button>
                          </Stack>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-text-small mb-0">
                          <thead className="thead-primary table-sorting">
                            <tr>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Id
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Timestamp
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                From Number
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                To Number
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Description
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Segments/Duration
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Amount
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Wallet Balance
                              </th>
                              <th
                                className="text-muted "
                                style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}
                              >
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading
                              ? loader?.map((e) => {
                                  return (
                                    <>
                                      <tr style={{ width: '100%', height: '1vh' }}>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          {' '}
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '10px 10px 10px 10px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Skeleton variant="text" width={'100%'} height={20} />
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })
                              : posts?.map((data, index) => {
                                  return (
                                    <tr key={data.id} style={{ width: '100%', height: '1vh' }} className="">
                                      <td
                                        className="text-success "
                                        style={{
                                          fontSize: '14px',
                                          padding: '10px 10px 10px 10px',
                                          fontWeight: '400',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {data.id}
                                      </td>
                                      <td
                                        className="text-dark"
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          padding: '10px 10px 10px 10px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {moment.tz(data.updatedAt, timeZone).format('MMMM Do YYYY, h:mm:ss a')}
                                      </td>
                                      <td
                                        className="text-dark"
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          padding: '10px 10px 10px 10px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {data.from}
                                      </td>
                                      <td
                                        className="text-dark"
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          padding: '10px 10px 10px 10px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {data.to}
                                      </td>
                                      <td
                                        className="text-dark"
                                        style={{
                                          fontSize: '14px',
                                          textAlign: 'center',
                                          padding: '10px 10px 10px 10px',
                                          fontWeight: '500',
                                        }}
                                      >
                                        {data.logType.includes('FEES') || data.logType.includes('_RECHARGE')
                                          ? data.description
                                          : `${data.description} - ref: ${
                                              data.logType === 'SMS' ? data.smsId : data.callId
                                            }`}
                                      </td>
                                      <td
                                        className="text-dark"
                                        style={{
                                          fontSize: '14px',
                                          textAlign: 'center',
                                          padding: '10px 10px 10px 10px',
                                          fontWeight: '500',
                                        }}
                                      >
                                        {data.logType === 'SMS' || data.logType === 'CALL'
                                          ? data.logType === 'SMS'
                                            ? `${data.messageSegments}`
                                            : formatSecondsToMinutesAndSeconds(data.callDuration)
                                          : '-'}
                                      </td>
                                      <td
                                        style={{
                                          fontSize: '14px',
                                          padding: '10px 10px 10px 10px',
                                          fontWeight: 'bolder',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {data.logType.includes(`_RECHARGE`) ? (
                                          <span className="text-success">+${Number(data.price).toFixed(4)}</span>
                                        ) : (
                                          <span className="text-danger">-${Number(data.price).toFixed(4)}</span>
                                        )}
                                      </td>
                                      <td
                                        className=" text-success"
                                        style={{
                                          fontSize: '14px',
                                          padding: '10px 10px 10px 10px',
                                          fontWeight: 'bolder',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {data.walletBalance > 0 ? (
                                          <span className="text-success">${Number(data.walletBalance).toFixed(4)}</span>
                                        ) : (
                                          <span className="text-danger">${Number(data.walletBalance).toFixed(4)}</span>
                                        )}
                                      </td>
                                      <td
                                        className="text-dark"
                                        style={{
                                          fontSize: '14px',
                                          textAlign: 'center',
                                          fontWeight: '500',
                                          padding: '10px 10px 10px 10px',
                                        }}
                                      >
                                        {data.logType === 'SMS' || data.logType === 'CALL'
                                          ? data.logType === 'SMS'
                                            ? data.smsStatus
                                            : data.callStatus
                                          : '-'}
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>
                      </div>
                      <div className="table-filter-info">
                        <div className="dt-pagination">
                          <ul className="dt-pagination-ul">
                            <li className={`dt-item ${currentPage === 0 ? 'disabled' : ''}`}>
                              <a className="dt-link" onClick={prevPageClick}>
                                Prev
                              </a>
                            </li>
                            <li className={`dt-item`}>
                              <a className={`dt-link`} onClick={nextPageClick}>
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal show={showExportModal} centered keyboard onHide={() => setShowExportModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Download transaction logs</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  {/* <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div> */}
                  <Form onSubmit={handleLogExport}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="fw-bold">Date Range</Form.Label>
                      <div className="d-flex">
                        <DatePicker
                          className="w-100"
                          maxDate={new Date()}
                          minDate={subMonths(new Date(), 6)}
                          selectsRange // Date range selecting enabled
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => {
                            setDateRange(update);
                          }}
                          dateFormat="dd MMM, yyyy"
                          calendarStartDay={1}
                          value={
                            dateRange[0] && dateRange[1]
                              ? `${format(dateRange[0], 'MMM dd, yyyy')} to ${format(dateRange[1], 'MMM dd, yyyy')}`
                              : dateRange[0]
                              ? format(dateRange[0], 'MMM dd, yyyy')
                              : '' // Handles single or no date selection
                          } // Starts from Monday
                          customInput={
                            <Form.Control
                              className="p-2"
                              type="input"
                              style={{
                                fontSize: '14px',
                              }}
                            />
                          }
                        />
                      </div>
                      <Form.Text className="text-muted mt-3">
                        We will email you the transaction logs for the selected date range in CSV format. This may take
                        a few minutes based on the size of the data.
                      </Form.Text>
                    </Form.Group>

                    <Button variant="contained" type="submit" disabled={!dateRange[0] || !dateRange[1]}>
                      Submit
                    </Button>
                  </Form>
                </div>
              </Modal.Body>
            </Modal>
          </>
        </div>
      </div>
    </>
  );
};

export default TgTransaction;
